<template>
  <div class="w-full md:mx-auto mt-8 grid grid-cols-1 md:grid-cols-2 grid-rows-1 gap-3 md:h-auto">
    <!-- <div
      v-for="(faq, i) in visibleData"
      :key="i + 1"
      class="relative cursor-pointer mb-1 w-full"
      @mouseenter="toggleAccordion(i + 1)"
    > -->
    <div v-for="(faq, i) in visibleData" :key="i + 1" class="relative cursor-pointer mb-1 w-full">
      <input type="checkbox" :checked="expanded[i + 1]" :id="`toggle${i + 1}`" class="toggle hidden" />
      <!-- <h1
        class="title block text-sm text-white font-bold cursor-pointer"
        :for="`toggle${i + 1}`"
        :class="expanded[i + 1] ? 'bg-dark-800 pad ' : 'full-pad backgrd'"
      > -->
      <h1
        class="title block text-sm text-darkColor full-pad bg-newAsh font-bold cursor-pointer"
        :for="`toggle${i + 1}`"
      >
        {{ faq.question }}
      </h1>

      <div
        v-if="expanded[i + 1]"
        class="dropdown_menu-6 content overflow-hidden text-white rounded-b-3xl -mt-4 z-10"
        :class="expanded[i + 1] ? ' bg-dark-800' : 'backgrd'"
      >
        <p v-html="faq.answer" style="padding-left: 24px" class="pt-2 pb-4 text-xs"></p>
      </div>

      <!-- <div class="bg-transparent absolute right-1 mt-2 top-4 w-10 pointer-events-none">
        <div v-show="expanded[i + 1]" class="focus:outline-none bg-none">
          <svg width="10" height="18" viewBox="0 0 10 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M1.25 1.5L8.75 9L1.25 16.5"
              stroke="#E3E5E5"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
        <div v-show="!expanded[i + 1]" class="focus:outline-none bg-none">
          <svg width="18" height="10" viewBox="0 0 18 10" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M16.5 1.25L9 8.75L1.5 1.25"
              stroke="#8D8E8D"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
      </div> -->
    </div>

    <!-- <Dropdown /> -->
    <!-- <button
      class="bg-none focus:outline-none text-right text-sm text-white"
      @click="seeMore(faqs.length === visibleData.length)"
    >
      {{ faqs.length === visibleData.length ? "See less" : "See more" }}
    </button> -->
  </div>
</template>

<script setup>
import { computed, onMounted, ref, watch } from "vue";
import Dropdown from "./Dropdown.vue";
const props = defineProps(["bool"]);
const watchBool = computed(() => props.bool);
const expanded = ref({
  1: false,
  2: false,
  3: false,
  4: false,
  5: false,
  6: false,
  7: false,
  8: false,
  9: false,
  10: false,
  11: false,
});
const visibleData = ref([]);
const faqs = ref([
  {
    question: "Zero and low-interest loans",
    answer: "Must have run payroll at least once before being selected, and completed KYC.",
  },
  {
    question: "Marketing and business grants",
    answer: "Must have run payroll at least once before being selected, and completed KYC.",
  },

  {
    question: "Zero charge on Annual PAYE filing",
    answer: "Must have run payroll at least four times before December, and completed KYC",
  },
  {
    question: "Rent, Electricity, and Internet Cover",
    answer: "Must have run payroll at least four times before December, and completed KYC",
  },
  {
    question: "Discounts on business tools",
    answer: "Pay for a higher tier, and run payroll at least once before being selected.",
  },
  {
    question: "Business advisory services",
    answer: "Must have run payroll at least once before being selected, and completed KYC",
  },
  {
    question: "⁠⁠Cashback on subscriptions and free upgrades",
    answer: "Must have run payroll at least twice before being selected, and completed KYC, referral count is a plus.",
  },
  {
    question: "Up to 100% Payroll relief",
    answer: "Must have run payroll at least twice with us, and completed KYC, referral count is a plus.",
  },
  // {
  //   question: "Diesel fee cover",
  //   answer: "Must have run payroll at least twice with us, and completed KYC",
  // },
  // {
  //   question: "PHCN bill cover",
  //   answer: "Must have run payroll at least twice with us, and completed KYC",
  // },
  // {
  //   question: "Internet subscriptions",
  //   answer: "Must have run payroll at least twice with us, and completed KYC",
  // },
  // {
  //   question: "Discounts for business tools",
  //   answer: "Must have run payroll at least twice with us, and completed KYC",
  // },
  // {
  //   question: "Business advisory services",
  //   answer: "Must have run payroll at least twice with us, and completed KYC",
  // },
  // {
  //   question: "Tier upgrade: Free PAYE, Pension & HMO remittance",
  //   answer: "Must have run payroll at least twice with us, and completed KYC",
  // },
]);

const empData = ref([
  {
    question: "House rent cover",
    answer: "You must have registered, completed KYC, and made bill payments",
  },
  {
    question: "Free internet subscriptions",
    answer: "You must have registered, complete KYC, make transfers",
  },
  {
    question: "Cashback on bill payment and transfers",
    answer:
      "You must have registered, completed KYC, paid for bills or made at least two transfers in the month, receiving your salary with WnP is a plus.",
  },
  {
    question: "Free wellness checks",
    answer: "You must have registered, completed KYC, paid for bills or made at least two transfers in the month.",
  },
  {
    question: "Up to 100% extra salary",
    answer: "You must have registered, completed KYC, paid for bills or made at least two transfers in the month.",
  },
  // {
  //   question: "Dry cleaning subscriptions",
  //   answer:
  //     "You must have registered, completed KYC, paid for bills or made at least two transfers in the month, referral count is a plus.",
  // },
  // {
  //   question: "Mental health wellness",
  //   answer:
  //     "You must have registered, completed KYC, paid for bills or made at least two transfers in the month, receiving your salary on WnP is a plus.",
  // },
  // {
  //   question: "Rent cover",
  //   answer:
  //     "You must have registered, completed KYC, paid for bills or made at least two transfers in the month, referral count is a plus",
  // },
]);
watch(watchBool, (newVal) => {
  if (newVal) {
    visibleData.value = [];
    visibleData.value.push(...faqs.value);
  } else {
    visibleData.value = [];
    visibleData.value.push(...empData.value);
  }
});
// const allData = computed(() => {
//   if (watchBool.value) {
//     return empData.value;
//   }
//   return faqs.value;
// });
// visibleData.value.push(...faqs.value.slice(0, 5));
visibleData.value.push(...faqs.value);

function seeMore(sz) {
  if (sz) {
    visibleData.value = [];
    visibleData.value.push(...faqs.value.slice(0, 5));
  } else {
    visibleData.value = [];
    visibleData.value = [...faqs.value];
  }
}

const toggleAccordion = (index) => {
  for (const i in expanded.value) {
    expanded.value[i] = false;
  }
  expanded.value[index] = !expanded.value[index];
};

onMounted(() => {
  faqs.value.forEach((fa, i) => {
    expanded.value[i + 1] = false;
  });
});
</script>

<style lang="scss" scoped>
// all the properties here should be added into tailwind.config.js
.dropdown_menu-6 {
  animation: growDown 300ms ease-in-out forwards;
  transform-origin: top center;
}

@keyframes growDown {
  0% {
    transform: scaleY(0);
  }
  80% {
    transform: scaleY(1.1);
  }
  100% {
    transform: scaleY(1);
  }
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.6s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.tall {
  align-self: start !important;
  align-items: start; /* Aligns this item to the start (top) of its grid cell */
}
.backgrd {
  background-color: #292a29;
}
.title {
  color: #474747;
  // color: #474747;
}
.transition-element {
  transition: opacity 0.3s ease 600ms;
  opacity: 0; /* Initially hidden */
}

.transition-element.active {
  opacity: 1; /* Fades in when active class is added */
}
.pad {
  padding: 24px;
  border-top-right-radius: 16px;
  border-top-left-radius: 16px;
}

.full-pad {
  padding: 24px;
  border-radius: 16px;
  // border-radius: 32px;
}
// if you need after/before pseudo elements please use https://github.com/croutonn/tailwindcss-pseudo-elements
.title::after,
.title::before {
  //   content: "";
  position: absolute;
  right: 1.25em;
  top: 1.25em;
  width: 2px;
  height: 0.75em;
  background-color: #7a7572;
  // transition: all 0.3s;
}

.title::after {
  transform: rotate(90deg);
}

.content {
  max-height: 0;
  transition: max-height 0.2s;
}

.toggle:checked {
  & + .title + .content {
    max-height: 500px;
  }
  & + .title::before {
    transform: rotate(90deg) !important;
  }
}
</style>
