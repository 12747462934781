<template>
  <div class="relative bg-mint py-7 md:py-16 w-full">
    <img src="./assets/arrow-2.png" class="absolute left-0 md:left-64 w-20 top-20 md:top-32" alt="" />

    <h4 class="font-bold text text-center text-primary-hover">Steps to Qualify for these amazing offers</h4>

    <img class="hidden md:block w-full md:w-8/12 mx-auto mt-3 md:mt-0" src="./assets/business-step.png" alt="" />
    <img class="md:hidden w-full md:w-8/12 mx-auto mt-3 md:mt-0" src="./assets/business-steps-mobile.png" alt="" />
  </div>
</template>

<script>
export default {};
</script>

<style scoped lang="scss">
@import url("../../styles/www.scss");
.text {
  font-size: 28px;
}
@screen lg {
  .text {
    font-size: 32px;
  }
}
</style>
