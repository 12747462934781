<template>
  <div class="">
    <SiteNav />

    <section class="bg-mint relative hero">
      <div
        style="border-radius: 60px"
        class="flex md:flex-row flex-col flex-col-reverse md:items-start items-center md:justify-between md:top-24 container overflow-hidden no-repeat w-full l-container p-5"
      >
        <!-- <div class="overlay"></div> -->
        <div class="pl-7 pt-1 w-full">
          <div class="hidden md:block bg-white rounded-full w-32 p-2 text-center text-sm text-primary-deep">
            #EazipayCares
          </div>

          <h1 class="md:block hidden mt-5 leading-10 head-font text-primary-deep">
            Supporting
            <span
              >Businesses & <br />
              Employees</span
            >
            through tough <br />
            times
          </h1>

          <h1 class="lg:hidden mt-6 w-full md:-ml-0 -ml-4 leading-10 text-center head-font text-primary-deep">
            Supporting Businesses
            <span> & Employees</span>
            through tough times
          </h1>

          <h4 class="mt-3 text-sm text-primary-deep md:text-left md:-ml-0 -ml-4 text-center">
            Together, we can weather any storm.
          </h4>
        </div>
        <div class="md:max-w-lg mx-auto">
          <div class="bg-white rounded-full w-32 p-2 text-center text-sm text-primary-deep mx-auto my-5 md:hidden">
            #EazipayCares
          </div>
          <img class="w-full md:w-10/12" src="./assets/learn-hero.png" />
        </div>
      </div>
    </section>

    <section style="z-index: 999 !important" class="w-full md:mt-8 round flex flex-col gap-6 pt-12 pb-10 md:pb-20">
      <div class="l-container relative w-full bg-white">
        <h2 class="text-center text-primary-deep font-bold md:text-3xl text-xl">
          At Eazipay, we understand the challenges businesses and employees <br />
        </h2>
        <h2 class="text-primary-deep font-bold md:text-3xl text-xl text-center">are facing right now</h2>

        <p class="text-center mt-4 text-sm md:text-base text-primary-deep">
          That's why we're launching the #EazipayCares campaign—to provide support, relief, and a helping <br />
          hand when it's needed most.
        </p>

        <!-- <img src="./assets/arrow.png" class="hidden lg:block absolute left-16 w-20 top-16" alt="" /> -->
      </div>
    </section>

    <section class="bg-landingGrey pt-2">
      <div class="w-full l-container">
        <div
          class="mt-9 mb-6 md:mt-16 md:mb-10 py-2 mx-auto justify-center cursor-pointer flex items-center gap-2 px-2 w-9/12 md:w-64 bg-white rounded-full shadow-lg"
        >
          <div
            @click="switchView"
            class="cursor-pointer"
            :class="
              bool
                ? ' bg-primary-deep text-center rounded-full w-full py-3 px-3 text-sm md:text-sm font-medium text-mint'
                : 'bg-white text-center text-dark-800 text-sm md:text-sm w-full '
            "
          >
            For Businesses
          </div>
          <div
            @click="switchView"
            class="cursor-pointer"
            :class="
              !bool
                ? ' bg-primary-deep text-center rounded-full w-full py-3 px-3 text-sm md:text-sm font-medium text-mint'
                : 'bg-white text-dark-800 text-sm md:text-sm w-full '
            "
          >
            For Employees
          </div>
        </div>
      </div>
      <div :class="bool ? 'promo' : 'promo-2'" class="relative my-12 bg-white w-full l-container">
        <div class="z-50 w-full px-4 mx-auto md:px-10">
          <img
            v-show="bool"
            class="hidden md:block mb-10 w-full md:w-8/12 mx-auto"
            src="./assets/learn-banner.png"
            alt=""
          />
          <img
            v-show="!bool"
            class="hidden md:block mb-10 w-full md:w-8/12 mx-auto"
            src="./assets/learn-banner2.png"
            alt=""
          />

          <img
            v-show="bool"
            class="md:hidden mb-10 w-full md:w-8/12 mx-auto"
            src="./assets/learn-banner-mobile.png"
            alt=""
          />
          <img
            v-show="!bool"
            class="md:hidden mb-10 w-full md:w-8/12 mx-auto"
            src="./assets/learn-banner-mobile-2.png"
            alt=""
          />
          <h3 v-if="bool" class="top-0 font-bold text-center text-primary-deep text-2xl">
            5,000 businesses stand a <br />chance to qualify for...
          </h3>

          <h3 v-else class="top-0 font-bold text-center text-primary-deep text-2xl">
            150,000 employees stand a <br />
            chance to win...
          </h3>

          <div
            style="border-radius: 20px"
            class="w-56 mt-8 mx-auto text-sm py-2 px-3"
            :class="bool ? 'bool-color text-primary' : 'no-bool-color text-secondary'"
          >
            <a class="flex items-center gap-4" href="#steps-section"
              ><span style="font-size: 14px">See steps to qualify below</span>
              <img class="w-4" v-show="bool" src="./assets/arrow.svg" alt="" />
              <img class="w-4" v-show="!bool" src="./assets/arrow-sec.svg" alt="" />
            </a>
          </div>

          <LearnAccordion :bool="bool" class="mt-10" />
        </div>
      </div>

      <section id="steps-section">
        <StepsComponentVue v-show="bool" class="mt-10" /> <StepsComponentVue2 v-show="!bool" class="mt-10" />
      </section>
    </section>

    <section class="w-full md:w-11/12 md:my-24 my-12 md:mx-auto" id="download-app">
      <AppDownload :bool="bool" />
    </section>
  </div>
</template>

<script>
import { watch, ref, onMounted } from "vue";
import LearnAccordion from "@/www/components/LearnAccordion.vue";
import StepsComponentVue from "./StepsComponent.vue";
import StepsComponentVue2 from "./StepsComponent2.vue";
import AppDownload from "../../components/AppDownload.vue";

export default {
  data() {
    return {
      hoverName: "",
      bool: true,
    };
  },
  components: { LearnAccordion, StepsComponentVue, StepsComponentVue2, AppDownload },
  methods: {
    async scrollToSection() {
      // Get a reference to the section element
      const section = this.$refs.calculator;

      // Ensure the section element exists
      if (section) {
        // Scroll to the section using scrollIntoView

        await this.$nextTick(() => section.scrollIntoView({ behavior: "smooth", block: "center" }));
        // this.$nextTick(() => section.scrollIntoView({ behavior: "smooth" }));
      }
    },
    switchView() {
      this.bool = !this.bool;
    },
  },
  async mounted() {
    this.scrollItem = true;
    // Call scrollToSection when the component is mounted
    // this.scrollToSection();
    await this.$nextTick();

    if (this.$route.query.scroll) {
      this.scrollToSection();
    }
  },
};
</script>

<style lang="scss" scoped>
@import url("../../styles/www.scss");

// .img {
//   background-image: linear-gradient(rgba(255, 255, 255, 0) rgba(17, 69, 59, 0.1) rgba(17, 69, 59, 1)),
//     url("./assets/team-pic.png");
// }
.round {
  border-top-left-radius: 80px !important;
}
.bool-color {
  background-color: rgba(17, 69, 59, 0.1);
}

.no-bool-color {
  background-color: rgba(255, 240, 239, 1);
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to bottom, transparent 0%, rgba(0, 0, 0, 0.6) 100%);
  opacity: 0;
  transition: opacity 0.3s ease;
}

.head-font {
  font-weight: 700;
  line-height: 40px;
  // font-family: "MADE Mellow", sans-serif;
  font-size: 32px;

  &.level-1 {
    font-size: 32px;
  }

  &.level-2 {
    font-size: 35px;
  }

  &.level-3 {
    font-size: 25px;
  }
}

.promo {
  // background: url("./assets/eligibility-bg.webp");
  background-repeat: no-repeat;
  background-size: cover;
  background-position-x: center;
  background-position-y: top;
  border-radius: 20px;
  padding: 40px 0 30px 0;
  height: auto;
  // overflow: auto;
}

.promo-2 {
  // background: url("./assets/eligibility-bg-2.webp");
  background-repeat: no-repeat;
  background-size: cover;
  background-position-x: center;
  background-position-y: top;
  border-radius: 20px;
  padding: 40px 0 30px 0;
  height: auto;
  // overflow: auto;
}
// .promo::before {
//   content: "";
//   position: absolute;
//   top: 0;
//   right: 0;
//   left: 0;
//   bottom: 0 !important;
//   width: 100%;
//   min-height: 800px;

//   background: linear-gradient(to top right, rgba(25, 25, 25, 1), rgba(22, 23, 22, 0.36));
// }
.text {
  opacity: 0;
}

.image-container {
  transition: all 0.3s ease;
}

.image-container:hover .overlay {
  opacity: 1;
}
.image-container:hover .text {
  opacity: 1;
}
.round {
  border-top-left-radius: 80px !important;
}
.hero {
  .container {
    position: relative;
    width: 100%;
    // height: 339.394px;

    height: auto;

    overflow: hidden;
  }
  .side-box {
    position: absolute;
    top: 3px;
    left: 20px;
    // border-radius: 20px;
    border: 0.5px solid rgba(57, 109, 99, 0.8);
    box-sizing: border-box;
    // box-shadow: 0 11.96px 23.92px 0 rgba(9, 59, 49, 0.25);

    width: 90%;
    height: auto;
    padding-bottom: 20px;
    background: #11453b;
    overflow: hidden;

    // top: 37px;
    // left: 30px;
    display: flex;
    // width: 554px;
    // height: 309px;
    // padding: 24px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 12.364px;
    flex-shrink: 0;
    border-radius: 31.899px;
    border: 1px solid rgba(57, 109, 99, 0.8);
    // background: linear-gradient(180deg, #11453b 2.78%, rgba(42, 171, 146, 0) 100%);
    box-shadow: 0px 11.962px 23.924px 0px rgba(9, 59, 49, 0.25);
  }
  .heading {
    color: #11453b;
  }
  #img {
    position: relative;
    overflow: hidden;
    border-radius: 60px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: right;
    // background: url("./assets/team-pic.png");
    //background: linear-gradient(rgba(255, 255, 255, 0) rgba(17, 69, 59, 1));
  }
  .img-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: contain;
    background-position: center;
    opacity: 0.6;
    background: linear-gradient(rgba(255, 255, 255, 0) rgba(17, 69, 59, 0.1) rgba(17, 69, 59, 1));
  }
}

.features-section {
  .feature-list {
    display: grid;
    grid-gap: 80px;

    li {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      gap: 28px;

      .media {
        max-width: 300px;
        flex-shrink: 0;
      }

      .content {
        max-width: 500px;
        text-align: center;
        margin-left: auto;
        margin-right: auto;
      }
      .title {
        font-size: 30px;
        margin-bottom: 16px;
      }
    }
  }

  li.compliance {
    margin-top: -25px; /* to reconcile the spacing at the top of the image */
  }
}

.payroll-calculator-section {
  background-color: #f0f7eb;
}

@screen lg {
  .head-font {
    font-weight: 700;
    line-height: 48px;
    // font-family: "MADE Mellow", sans-serif;
    font-size: 36px;

    &.level-1 {
      font-size: 32px;
    }

    &.level-2 {
      font-size: 35px;
    }

    &.level-3 {
      font-size: 25px;
    }
  }
  .hero {
    .container {
      position: relative;
      width: 100%;

      // height: 100vh;
      // background: linear-gradient(
      //   to right,
      //   rgba(17, 69, 59, 1),
      //   40%,
      //   rgba(17, 69, 59, 0.7),
      //   rgba(17, 69, 59, 0.1),
      //   rgba(255, 255, 255, 0)
      // );
      height: 564px;

      // background: url("./assets/learn-more.webp");
      background-size: cover;
      overflow: hidden;
    }
    .side-box {
      //   position: absolute;
      //   top: 30px;
      //   left: 30px;
      //   border-radius: 32px;
      //   border: 0.5px solid rgba(57, 109, 99, 0.8);
      //   box-sizing: border-box;
      //   box-shadow: 0 11.96px 23.92px 0 rgba(9, 59, 49, 0.25);

      //   width: 53%;
      //   height: auto;
      //   padding-bottom: 20px;
      //   z-index: 50;
      //   background: linear-gradient(to bottom, rgba(17, 69, 59, 1), rgba(42, 171, 146, 0));
      //   overflow: hidden;
      // }
      position: absolute;
      top: 37px;
      left: 30px;
      display: flex;
      width: 554px;
      height: 309px;
      padding: 24px;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      gap: 12.364px;
      flex-shrink: 0;
      border-radius: 31.899px;
      border: 1px solid rgba(57, 109, 99, 0.8);
      background: linear-gradient(180deg, #11453b 2.78%, rgba(42, 171, 146, 0) 100%);
      box-shadow: 0px 11.962px 23.924px 0px rgba(9, 59, 49, 0.25);
    }
  }
  .promo {
    // background: url("./assets/eligibility-bg.webp");
    background-repeat: no-repeat;
    background-size: cover;
    background-position-x: right;
    background-position-y: top;
    border-radius: 40px;
    height: auto;
    padding: 40px 0 30px 0;
    // overflow: auto;
  }

  .promo-2 {
    // background: url("./assets/eligibility-bg-2.webp");
    background-repeat: no-repeat;
    background-size: cover;
    background-position-x: right;
    background-position-y: top;
    border-radius: 40px;
    height: auto;
    padding: 40px 0 30px 0;
    // overflow: auto;
  }
  // .promo::before {
  //   content: "";
  //   position: absolute;
  //   top: 0;
  //   right: 0;
  //   left: 0;
  //   bottom: 0 !important;
  //   width: 100%;
  //   min-height: 100%;

  //   background: linear-gradient(to top right, rgba(25, 25, 25, 1), rgba(22, 23, 22, 0.36));
  // }
  .features-section {
    .feature-list {
      grid-gap: 100px;

      li {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        gap: 0;

        .media {
          width: 45%;
          max-width: unset;
          flex-shrink: 0;
        }

        .content {
          width: 50%;
          max-width: unset;
          margin-left: 0;
          margin-right: 0;
          text-align: left;
        }
        .title {
          font-size: 50px;
          margin-bottom: 16px;
        }
      }

      li.reverse {
        flex-direction: row-reverse;
      }
      li.compliance {
        margin-top: 0px; /* to */
      }
    }
  }
}
</style>
